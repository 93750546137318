import React from 'react'
import styled from 'styled-components'
import styles from '../styles/heading'

const Heading5 = styled.h5`
  ${styles}

  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
`

export default ({ children, className }) => <Heading5 className={className}>{children}</Heading5>
