import React from 'react'
import styled from 'styled-components'
import styles from '../styles/heading'

const Heading2 = styled.h2`
  ${styles}

  font-size: ${({ theme }) => theme.fontSizes[11]};
  line-height: ${({ theme }) => theme.verticalRythm[10]};
`

export default ({ children, className }) => <Heading2 className={className}>{children}</Heading2>
