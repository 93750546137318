import { css } from 'styled-components'

export default css`
  padding-left: 0;
  margin: 0 0 ${({ theme }) => theme.verticalRythm[9]} 0;
  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.verticalRythm[10]};
  list-style-position: inside;

  li {
    margin-bottom: 0;
  }

  li & {
    margin: 0;
  }

  li > ul,
  li > ol {
    padding-left: 0;
  }
`
