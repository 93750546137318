import React from 'react'
import styled from 'styled-components'

const Blockquote = styled.blockquote`
  margin: 0 0 0 ${({ theme }) => theme.verticalRythm[9]};
  padding: 0;
  font-style: italic;
`

export default ({ children, className }) => <Blockquote className={className}>{children}</Blockquote>
