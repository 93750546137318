import React from 'react'
import RehypeReact from 'rehype-react'
import Heading1 from './elements/heading1'
import Heading2 from './elements/heading2'
import Heading3 from './elements/heading3'
import Heading4 from './elements/heading4'
import Heading5 from './elements/heading5'
import Heading6 from './elements/heading6'
import Paragraph from './elements/paragraph'
import UnorderedList from './elements/unordered-list'
import OrderedList from './elements/ordered-list'
import Blockquote from './elements/blockquote'
import Hr from './elements/horizontal-rule'

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    p: Paragraph,
    ul: UnorderedList,
    ol: OrderedList,
    blockquote: Blockquote,
    hr: Hr,
  },
}).Compiler

export default ({ ast, className }) => <div className={className}>{renderAst(ast)}</div>
