import React from 'react'
import styled from 'styled-components'

const Hr = styled.hr`
  margin: ${({ theme }) => theme.verticalRythm[9]} 0;
  height: ${({ theme }) => theme.verticalRythm[2]};
  background-color: ${({ theme }) => theme.colors.text};
  border: none;
`

export default ({ className }) => <Hr className={className} />
