import React from 'react'
import styled from 'styled-components'
import styles from '../styles/heading'

const Heading6 = styled.h6`
  ${styles}

  font-size: ${({ theme }) => theme.fontSizes[7]};
  line-height: ${({ theme }) => theme.verticalRythm[8]};
`

export default ({ children, className }) => <Heading6 className={className}>{children}</Heading6>
