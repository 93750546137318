import React from 'react'
import styled from 'styled-components'
import styles from '../styles/heading'

const Heading4 = styled.h4`
  ${styles}

  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
`

export default ({ children, className }) => <Heading4 className={className}>{children}</Heading4>
